<template>
  <div class="SecondKillOrder_I">
    <div class="headNavs">
      <div class="headTilte">秒杀订单</div>
      <div class="headDetails">说明：包含订单编号、买家昵称、支付方式、状态、下单时间</div>
    </div>
    
    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入订单ID">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelect">
        <el-table-column prop="id" label="订单ID" width="65" align="center"></el-table-column>
        <el-table-column prop="name" label="站点" width="65" align="center">
          <template #default="scope">
            <span>{{ scope.row.site ? scope.row.site.name : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="order_no" label="订单编号" width="220" align="center"></el-table-column>
        <el-table-column prop="nickname" label="买家昵称" width="120" align="center">
          <template #default="scope">
            <span>{{ scope.row.user ? scope.row.user.nickname : '' }}</span>
          </template>
        </el-table-column> 
        <el-table-column prop="mobile" label="联系电话" width="115" align="center">
          <template #default="scope">
            <span>{{ scope.row.user ? scope.row.user.mobile : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="username" label="服务商" width="120" align="center">
          <template #default="scope">
            <span>{{ scope.row.business ? scope.row.business.username : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="goods_name" label="商品名称" width="140" align="center">
          <template #default="scope">
            <span>{{ scope.row.goodsone ? scope.row.goodsone.goods_name : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="total_num" label="商品数量" width="80" align="center">
          <template #default="scope">
            <span>{{ scope.row.goodsone ? scope.row.goodsone.total_num : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pay_price" label="支付金额" width="80" align="center"></el-table-column>
        <el-table-column prop="pay_type_text" label="支付方式" width="90" align="center"></el-table-column>
        <el-table-column prop="order_status_text" label="订单状态" width="90" align="center"></el-table-column>
        <el-table-column prop="is_confirm_text" label="接单状态" width="90" align="center"></el-table-column>
        <el-table-column prop="back_status_text" label="退款状态" width="90" align="center"></el-table-column>
        <el-table-column prop="createtime" label="下单时间" width="130" align="center"></el-table-column>
        <el-table-column label="操作" align="center"  >
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(scope.$index,scope.row)"
              >订单详情
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>


    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew" style="width:760px">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <div class="headNavs" style="padding:0 26px">
          <el-menu
            :default-active="acndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect2">
            <el-menu-item index="1">订单详情</el-menu-item>
            <el-menu-item index="2">订单进程</el-menu-item>
          </el-menu>
          <div class="NavLiQuery" style="float: right;" @click="dialogVisible = true" v-if="Row.is_confirm == 0" >转移订单</div>
        </div>
        <div v-if="info_Show == 0">
          <div class="EditNewForm"  style="padding:24px 35px">
            <div class="EditNewFormItem">
              <div>订单编号：{{Row.order_no ? Row.order_no : '无'}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>买家昵称：{{Row.user ? Row.user.nickname : ''}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>服务商：{{Row.business ? Row.business.username : ''}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>联系电话：{{Row.user ? Row.user.mobile :''}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>商品名称：{{Row.goodsone ? Row.goodsone.goods_name : ''}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>订单状态：{{Row.order_status_text ? Row.order_status_text : '无'}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>商品规格：{{Row.goodsone ? Row.goodsone.goods_attr : '无' }}</div>
            </div>
            <div class="EditNewFormItem">
              <div>下单时间：{{Row.createtime ? Row.createtime : '无'}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>商品单价：{{Row.goodsone ? Row.goodsone.goods_price : '无'}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>支付时间：{{Row.pay_time_text ? Row.pay_time_text : '无'}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>商品数量：{{Row.goodsone ? Row.goodsone.total_num : '无'}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>退款状态：{{Row.back_status_text ? Row.back_status_text : '无'}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>商品金额：{{Row.total_price ? Row.total_price : '无'}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>支付方式：{{Row.pay_type_text ? Row.pay_type_text : '无'}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>支付金额：{{Row.pay_price ? Row.pay_price : '无'}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>接单状态：{{Row.is_confirm_text ? Row.is_confirm_text : ''}}</div>
            </div>
            <div class="EditNewFormItem">
              <div>发票类型：{{Row.invoice ? Row.invoice : '无' }}</div>
            </div>
            <div class="EditNewFormItem">
              <div>服务周期：{{Row.service.time ? Row.service.time : '未确定时间'}} <span v-if="Row.service.time !=''"> {{Row.service.type ? Row.service.type : '天'}}</span></div>
            </div>
            <div class="EditNewFormItem">
              <div>订单备注：{{Row.remark ? order.remark : "无"}}</div>
            </div>
          </div>
          <div class="EditNewFormBtns">
            <div class="order">点击确认订单状态</div>
          </div>
          <div class="receiving">
            <div>
              <div class="OrderReceiving" v-if="Row.is_confirm == 0" @click="jiedan(1)">未接单</div>
              <div class="OrderReceiving active" v-else>已接单</div>
            </div>
            <div><span>→</span></div>
            <div>
              <div class="OrderReceiving" v-if="Row.order_status < 30 ">未办理</div>
              <div class="OrderReceiving active" v-if="Row.order_status >= 30 ">办理中</div>
            </div>
            <div><span>→</span></div>
            <div>
              <div class="OrderReceiving" v-if="Row.order_status < 40 " @click="jiedan()">未完成</div>
              <div class="OrderReceiving active" v-if="Row.order_status >= 40 ">已完成</div>
            </div>
            <div><span>→</span></div>
            <div>
              <div class="OrderReceiving" v-if="Row.is_comment == 0">未评价</div>
              <div class="OrderReceiving active" v-else>已评价</div>
            </div>
          </div>
        </div>
        <div class="EditNewForm" v-if=" info_Show == 1">
          <div class="EditNewFormBtns">
            <div class="speed">及时更新最新进展，让客户知晓实时进度</div>
          </div>
          <div class="EditNewFormBtns">
            <div class="EditNewFormItemIpt">
              <el-input type="textarea" v-model.trim="row.info" placeholder="请输入实时进度"></el-input>
            </div>
          </div>
          <div class="EditNewFormBtns" style="margin-top:30px">
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  ref="upload-image"
                  list-type="picture-card"
                  :action="upload"
                  :headers="heads"
                  :limit="1"
                  :file-list="fileList"
                  :on-success="handleSuccess"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </div>
          <div class="EditNewFormBtns" style="border-bottom: 1px solid rgb(204, 204, 204);margin-right: 30px;">
            <div class="NavLiQuery sss" @click="toUpdate()">更新</div>
          </div>
          <div class="record">
            <div class="EditNewFormBtns">
              <div class="speed">全部跟进记录</div>
            </div>
            <div v-for="item in flowList " :key="item.id" class="li">
              <div class="img"><img :src="item.business.full_avatar" alt=""></div>
              <div class="process">
                <div>{{item.business.username}}</div>
                <div>{{item.createtime}} 更新了服务进程</div>
                <div>{{item.info}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--确定接单-->
    <el-dialog v-model="dialogVisible1" :title="titleMath" width="30%" :before-close="handleClose" >
      <div class="EditNewForm" style="padding: 0 24px;" v-if="info == 0">
        <div class="EditNewFormBtns aa">
          <div class="EditNewFormItemName"><span class="signSpan">* </span>服务周期：</div>
          <div class="EditNewFormItemIpt">
            <el-input type="number" v-model="transfer.time" placeholder="请填写订单的服务周期， 示例: 30"></el-input>
          </div>
        </div>
        <div class="EditNewFormBtns aa">
          <div class="Holiday">
            <div><span class="signSpan">* </span>周期类型：</div>
            <div>
              <el-radio v-model="transfer.type" label="天" size="large">天</el-radio>
              <el-radio v-model="transfer.type" label="月" size="large">月</el-radio>
              <el-radio v-model="transfer.type" label="年" size="large">年</el-radio>
            </div>
          </div>
        </div>
        <div class="EditNewFormBtns aa">
          <div class="EditNewFormItemName"><span class="signSpan">* </span>用户姓名：</div>
          <div class="EditNewFormItemIpt">
            <el-input v-model="transfer.realname" placeholder="请填写客户姓名"></el-input>
          </div>
        </div>
      </div>
      <div v-if="info == 1"><span>确认完成订单？</span></div>
      <template #footer >
          <span class="dialog-footer">
            <el-button @click="dialogVisible1 = false">取消</el-button>
            <el-button type="primary" @click="receiving()" v-if="info == 0">确认</el-button>
            <el-button type="primary" @click="complete()" v-if="info == 1">确认</el-button>
          </span>
        </template>
    </el-dialog>

    <!--转移订单-->
    <el-dialog v-model="dialogVisible" title="转移订单" width="30%" :before-close="handleClose" >
      <div class="EditNewForm" style="padding: 0 24px;">
        <div class="EditNewFormBtns aa">
          <div class="EditNewFormItemName"><span class="signSpan">* </span>订单编号：</div>
          <div class="EditNewFormItemIpt">
            <el-input v-model="Row.order_no" disabled></el-input>
          </div>
        </div>
        <div class="EditNewFormBtns aa">
          <div class="EditNewFormItemName"><span class="signSpan">* </span>商品名称：</div>
          <div class="EditNewFormItemIpt">
            <el-input v-model="Row.goodsone.goods_name" disabled></el-input>
          </div>
        </div>
        <div class="EditNewFormBtns aa">
          <div class="EditNewFormItemName"><span class="signSpan">* </span>服务商：</div>
          <div class="EditNewFormItemIpt">
            <el-select v-model="business_id" placeholder="请选择服务商">
              <el-option v-for="item in userGoods.businessList" :key="item.id" :label="item.username" :value="item.id"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="transferAdd(Row.business_id)">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      fileList:[],
      tableData:[],//总列表
      flowList:[],  //订单进程列表
      userGoods:[],  //拼团选项列表
      page: {   // 分页
        currentPage: 1,
        pageSize: 10,
      },
      //搜索参数
      search: {
        keyword: "",
      },
      Row:{
        order_no:'', //订单编号
        is_confirm_text:'',  //接单状态
        total_price:'', //总价
        order_status_text:'', //订单状态
        pay_status:'', //支付状态
        pay_price:'', //付款金额
        back_status_text:'', //退款状态
        invoice:'', //是否开发票
        createtime:'', //订单创建时间
        pay_type_text:'',  //支付方式
        pay_time_text:'',  //支付时间
        remark:'' , //订单备注 
        business:{
          username:''
        },
        user:{
          nickname:'',
          mobile:"",
          avatar:''
        },
        service:{
          time:'' ,//服务周期
          type:''
        },
        goodsone:{
           total_num:'', //数量
           goods_attr:'', // 规格
           goods_price:'', //商品价格
           goods_name:'', //商品名称
        }
      },
      row:{
        info:'', //最新消息
        images: [],
      },
      transfer:{  //接单
        realname:'',
        time:'',
        type:'',
      },
      business_id:'',
      editPanel: false,
      dialogVisible:false,
      dialogVisible1: false,
      titleMath:'',
      editId:'',
      total: 0,
      RowId:'',
      acndex:1,  //弹出层选择
      info_Show:0, // 控制编辑新增
      info:0, // 控制编辑新增

    }
  },
  mounted() {
    var that = this
    that.loadTable();
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
  },
  methods: {  
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/order/miaosha/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if( e == false){
        that.search.keyword = ""
      }
      that.loadTable();
    },
    //获取编辑内容
    HandleEdit(e, data) {
      var that = this
      that.editPanel = true;
      that.titleMath = '拼团详情'
      that.editId = data.id
      axios.get('/admin/order/miaosha/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        that.Row = res.data.data.row
        that.RowId = res.data.data.row.id
      })
      axios.get('/admin/order/pintuan/userGoods',{   // 服务商
      }).then(function (res) {
        that.userGoods = res.data.data
    })
    },
    //新增编辑控制按钮
    handleSelect2(key) {
      if (key == 1) {
        this.info_Show = 0;
      } else if(key == 2){
        this.info_Show = 1
        this.showAdd()
        this.fileList = []
      } 
    },
    // 获取进程列表
    showAdd(){
      var that = this
      let aeeid = that.RowId 
      axios({
        method: 'GET',
        url: '/admin/order/order/showflow',
        params: {
          id: aeeid,
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.flowList = res.data.data.flowList
        }
      })
    },
    //更新进程列表
    toUpdate(){
      var that = this
      let aeeid = that.RowId 
      if(that.row.info != ''){
        axios({
          method: 'post',
          url: '/admin/order/order/addflow',
          data: {
            id : aeeid,
            row: that.row
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.row.info = ''
            that.showAdd();
          }
        })
      }
    },
    //转移订单
    transferAdd(id){
      var that = this
      let arrId = id
      let aeeid = that.RowId 
      axios({
        method: 'post',
        url: '/admin/order/order/transfer',
        data: {
          id : aeeid,
          row:{
            business_id: that.business_id
          }
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.dialogVisible = false
        }
      })
    },
    //确定接单
    receiving(){
      var that = this
      let aeeid = that.RowId 
      axios({
        method: 'post',
        url: '/admin/order/order/confirm',
        data: {
          id : aeeid,
          row: that.transfer
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.dialogVisible1 = false
        }
      }) 
    },
    //完成订单
    complete(){
      var that = this
      let aeeid = that.RowId 
      axios({
        method: 'post',
        url: '/admin/order/order/finish',
        data: {
          id : aeeid,
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
        }
      })
      that.dialogVisible1 = false
    },
    //接单
    jiedan(e){
      this.dialogVisible1 = true
      if(e == 1){
        this.transfer = {
          type : '天'
        }
        this.info = 0
        this.titleMath = '确定接单'
      }else{
        this.info = 1
        this.titleMath = '提示'

      }
    },
    // 关闭
    Close() {
      var that = this
      that.editPanel = false;
      that.info_Show = 0
    },
    //图片上传成功
    handleSuccess(e) {
      var that = this
      that.Row.images = e.data.full_avatar
    },
    handleExceed  (files)  {
      this.$refs["upload-images"].clearFiles()
      this.$refs["upload-images"].handleStart(files[0])
      this.$refs["upload-images"].submit();
    },
    handlePreview(file) {
      console.log(file);
      window.open(file.url);
    }, 
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    }, 
  }
}
</script>
<style scoped>
.EditNewFormItem{
  height: 40px;
  line-height: 40px;
}
.order{
  height: 80px;
  line-height: 75px;
  text-indent: 2em;
  font-weight: 700;
  font-size: 15px;
  background: #e8e8e8;
}
.receiving{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 50px;
}
.active{
  background-color: #3795FF;
  color: #fff;
}
.speed{
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 15px;
}
.sss{
  width: 30px;
  margin: 20px 0;
}
.record{
  padding: 25px 10px 10px;
}
.img{
  width: 60px;
  height: 60px;
  overflow: hidden;
}
.process{
  width: 91%;
}
.process div:nth-child(1){
  font-size: 14px;
  margin-bottom: 5px;
}
.process div:nth-child(2){
  font-size: 14px;
  color: #9EA0A2;
}
.process div:nth-child(3){
  min-height: 20px;
  background-color: #f5f5f5;
  font-size: 14px;
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 5px;
}
.li{
  display:flex;
  padding-bottom: 15px;
  width: 98%;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}
.aa{
  margin-bottom: 20px;
}
.Holiday{
  display: flex;
}
.Holiday div:nth-child(1){
  margin-right: 30px;
}

</style>
